var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.filtersVisible)?_c('v-col',{attrs:{"cols":_vm.filterColumnCount}},[_c('base-card',[_c('v-card-text',{staticClass:"-pt-0"},[_c('advanced-grid-filters-list',{attrs:{"dynamic":false,"fields":_vm.filterfields},on:{"search":_vm.onSearch},model:{value:(_vm.filtercriteria),callback:function ($$v) {_vm.filtercriteria=$$v},expression:"filtercriteria"}})],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.gridColumnCount}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex"},[_c('h5',{staticClass:"ma-5 mb-0 d-flex flex"},[_vm._v("Users")]),_c('v-text-field',{staticClass:"col-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"input":_vm.triggerQuicksearch},model:{value:(_vm.quicksearch),callback:function ($$v) {_vm.quicksearch=$$v},expression:"quicksearch"}}),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.toggleFilterView}},[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filters ")],1),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"danger","to":"/mng/system/users/invite"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add User ")],1)],1)]),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"headers":_vm.headers,"options":_vm.pagingOptions,"server-items-length":_vm.totalItemCount,"items":_vm.gridData,"item-key":"name","show-select":false,"multi-sort":""},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":require("@/assets/images/avatars/001-man.svg"),"alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.fullname)+" ")])],1)]}},{key:"item.aud_create_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.aud_create_date))+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":"","to":'/mng/system/users/edit/' + item.code}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]:(item.status === 'Invited')?[_c('v-chip',{attrs:{"color":"info","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-timer-sand")]),_vm._v(" Awaiting Activation ")],1)]:(item.status === 'Awaiting Activation')?[_c('v-chip',{attrs:{"color":"info","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-timer-sand")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]:(item.status === 'Disabled')?[_c('v-chip',{attrs:{"color":"error","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]:_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }